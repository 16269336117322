import { Component,  EventEmitter,   OnInit,  Output,  computed,   effect,   inject  } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon'; 
import { MatListModule } from '@angular/material/list';
import {  NavigationEnd, Router, RouterModule  } from '@angular/router'; 
import { AsyncPipe, JsonPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common'; 
import { MatSelectModule } from '@angular/material/select';  
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown'; 
import {  filter  } from 'rxjs';
import { TranslationService } from '../../views/translations/services/translations.service';
import { AppVersionComponent } from '../app-version/app-version.component';
import { FirebaseAuthService } from '../../views/auth/services/firebase-auth.service';



@Component({
  selector: 'app-sidebarmenu',
  standalone: true,
  imports: [   
    ReactiveFormsModule, 
    FormsModule,
    RouterModule, 
    AsyncPipe,
    JsonPipe,
    NgIf,
    NgFor,
    UpperCasePipe, 
    MatDividerModule,
    MatIconModule,
    MatListModule, 
    MatSelectModule,
    ButtonModule,
    AvatarModule,
    BadgeModule,
    PanelMenuModule,
    DropdownModule,
    AppVersionComponent
  ], 
  templateUrl: './sidebarmenu.component.html',
  styleUrl: './sidebarmenu.component.scss'
})
export class SidebarmenuComponent implements OnInit{

  @Output() toggeleSidenav = new EventEmitter<string>(); 

  fbAuthService = inject(FirebaseAuthService);   
  userRole = computed(() => this.fbAuthService.crmCurrentUser()?.role);
  comboLanguages = computed( ()=> [...this.translationServ.languagesArray()] ) ; 
  selectedLang = {};

  //menu items
  menuitems: MenuItem[] = [];
  activeItem: string = '';


  constructor(
    private router: Router,   
    private translationServ : TranslationService ) { 

      this.router.events.subscribe((event) => {
        this.activeItem = this.router.url; 
      }); 
  
      this.translationServ.getFirebaselangs();  
      
      effect(  () => {
          this.selectedLang = {...this.translationServ.selectedLang()};
     
          this.menuitems = [];
          this.designMenu();  
      });
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateMenuExpansion();
    });

    // Inizializza l'espansione dei menu alla prima visita
    this.updateMenuExpansion();
  }

  private updateMenuExpansion(): void { 
    this.menuitems.forEach(menuItem => { 
      if (menuItem.items) { 
        menuItem.expanded = this.expandIfIn(menuItem.items.map(item => item.routerLink[0]));
      }
    });
  }

  designMenu() { 
    this.menuitems = [
      // {
      //     key: '0',
      //     label: this.translationServ.translationsSIG()?.['label-dashboard'] || 'label-dashboard' , 
      //     icon: 'pi pi-objects-column',  
      //     expanded: this.expandIfIn(['/dashboard', '/expenses']),  
      //     items: [            
      //         {
      //           key: '0_1',
      //           icon: 'pi pi-chart-line',
      //           label: this.translationServ.translationsSIG()?.['label-ravenue'] ?? 'label-ravenue' ,  
      //           routerLinkActive: 'menuactive',
      //           routerLink: ['/dashboard'],                   
      //           command: () => {
      //             this.activeItem = '/dashboard';
      //             this.router.navigate(['/dashboard']);
      //           }
      //         },
      //         {
      //             key: '0_1',
      //             icon: 'pi pi-chart-bar',
      //             label: this.translationServ.translationsSIG()?.['label-expenses'] ?? 'label-expenses' , 
      //             routerLink: ['/expenses'],     
      //             disabled: true 
      //         }    
      //     ]
      // },
      {  //** CUSTOMERS ************************
            key: '1',
            label: this.translationServ.translationsSIG()?.['label-users'] ?? 'label-users' , 
            icon: 'pi pi-users',
            expanded: this.expandIfIn(['/crmusers', '/customers', '/rifid']),
            items: [
                {
                    key: '1_0',
                    label: this.translationServ.translationsSIG()?.['label-crm-users'] ?? 'label-crm-users' , 
                    icon: 'pi pi-users', 
                    routerLinkActive: 'menuactive',
                    routerLink: ['/crmusers'],                   
                    command: () => {
                      this.activeItem = '/crmusers';
                      this.router.navigate(['/crmusers']);
                    },
                    disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
                },
                {
                  key: '1_1',
                  label: this.translationServ.translationsSIG()?.['label-customers'] ?? 'label-customers' , 
                  icon: 'pi pi-users',
                  routerLinkActive: 'menuactive',
                  routerLink: ['/customers'],                   
                  command: () => {
                    this.activeItem = '/customers';
                    this.router.navigate(['/customers']);
                  },
                  disabled: ( this.userRole() === 'guest' ) ? true : false    //! ROLE PERMISSION
                },
                {
                  key: '1_2',
                  label: this.translationServ.translationsSIG()?.['label-rifid'] ?? 'label-rifid' , 
                  icon: 'pi pi-id-card',
                  routerLinkActive: 'menuactive',
                  routerLink: ['/rifid'],                   
                  command: () => {
                    this.activeItem = '/rifid';
                    this.router.navigate(['/rifid']);
                  },
                  disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
                }
            ]
      },
      { //** PUN ************************
        key: '2',
        label: this.translationServ.translationsSIG()?.['label-charge'] ?? 'label-charge' , 
        icon: 'pi pi-bolt',
        expanded: this.expandIfIn(['/stations', '/pun']),
        items: [
            {
                key: '2_0',
                label: this.translationServ.translationsSIG()?.['label-charging-stations'] ?? 'label-charging-stations' , 
                icon: 'pi pi-sparkles',
                routerLinkActive: 'menuactive',
                routerLink: ['/stations'],                   
                command: () => {
                  this.activeItem = '/stations';
                  this.router.navigate(['/stations']);
                },
                disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
            },
            {
              key: '2_1',
              label: this.translationServ.translationsSIG()?.['label-charging-stations-pun'] ?? 'label-charging-stations-pun' , 
              icon: 'pi pi-code',
              routerLinkActive: 'menuactive',
              routerLink: ['/pun'],                   
              command: () => {
                this.activeItem = '/pun';
                this.router.navigate(['/pun']);
              },
              disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
            }
          ]
      },
      // {
      //   key: '3',
      //   label: this.translationServ.translationsSIG()?.['label-sharing'] ?? 'label-sharing' , 
      //   icon: 'pi pi-arrow-right-arrow-left',
      //   expanded: this.expandIfIn(['/cars', '/scooters', '/bikes']),
      //   items: [
      //       {
      //         key: '3_0',
      //         label: this.translationServ.translationsSIG()?.['label-cars'] ?? 'label-cars' , 
      //         icon: 'pi pi-car',
      //         routerLinkActive: 'menuactive',
      //         routerLink: ['/cars'],       
      //         disabled: true 
      //       },
      //       {
      //         key: '3_1',
      //         label: this.translationServ.translationsSIG()?.['label-scooters'] ?? 'label-scooters' , 
      //         icon: 'pi pi-map-marker',
      //         routerLinkActive: 'menuactive',
      //         routerLink: ['/scooters'],      
      //         disabled: true  
      //       },
      //       {
      //         key: '3_2',
      //         label: this.translationServ.translationsSIG()?.['label-bikes'] ?? 'label-bikes' , 
      //         icon: 'pi pi-map-marker',
      //         routerLinkActive: 'menuactive',
      //         routerLink: ['/bikes'],      
      //         disabled: true  
      //       }
      //   ]
      // },
      { //** RECHARGES ************************
        key: '3',
        label: this.translationServ.translationsSIG()?.['label-recharges'] ?? 'label-recharges' , 
        icon: 'pi pi-bolt',
        expanded: this.expandIfIn(['/recharges']),
        items: [
            {
                key: '3_0',
                label: this.translationServ.translationsSIG()?.['label-recharges'] ?? 'label-recharges' , 
                icon: 'pi pi-sparkles',
                routerLinkActive: 'menuactive',
                routerLink: ['/recharges'],                   
                command: () => {
                  this.activeItem = '/recharges';
                  this.router.navigate(['/recharges']);
                },
                disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
            } 
          ]
      },
      { //** CALENDARI ************************
      key: '4',
      label: this.translationServ.translationsSIG()?.['label-calendar'] ?? 'label-calendar' , 
      icon: 'pi pi-calendar',
      expanded: this.expandIfIn(['/companycal', '/personalcal']),
      visible: ( this.userRole() === 'superadmin' ) ? true : false,   //! ROLE PERMISSION
      items: [
          {
              key: '4_0',
              label: this.translationServ.translationsSIG()?.['label-company-cal'] ?? 'label-company-cal' , 
              icon: 'pi pi-calendar', 
              routerLinkActive: 'menuactive',
              routerLink: ['/companycal'],                   
              command: () => {
                this.activeItem = '/companycal';
                this.router.navigate(['/companycal']);
              },
              disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
          }, 
          {
              key: '4_1',
              label: this.translationServ.translationsSIG()?.['label-personal-cal'] ?? 'label-personal-cal' , 
              icon: 'pi pi-calendar',
              disabled: false,
              routerLinkActive: 'menuactive', 
              routerLink: ['/personalcal'],                   
              command: () => {
                this.activeItem = '/personalcal';
                this.router.navigate(['/personalcal']);
              }
          } 
      ]
      },
      { //** ANALYTICS ************************
        key: '5',
        label: this.translationServ.translationsSIG()?.['label-analytics'] ?? 'label-analytics' , 
        icon: 'pi pi-chart-line',
        expanded: this.expandIfIn(['/analytics/delivered/2023', '/analytics/dash' ]),
        items: [
            {
                key: '5_0',
                label: this.translationServ.translationsSIG()?.['label-dashboard'] ?? 'label-dashboard' , 
                icon: 'pi pi-chart-bar', 
                routerLinkActive: 'menuactive',
                routerLink: ['/analytics/dash'],                   
                command: () => {
                  this.activeItem = '/analytics/dash';
                  this.router.navigate(['/analytics/dash']);
                },
                disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
            },
            {
              key: '5_1',
              label: this.translationServ.translationsSIG()?.['label-delivered'] ?? 'label-delivered' , 
              icon: 'pi pi-chart-bar', 
              routerLinkActive: 'menuactive',
              routerLink: ['/analytics/delivered/2023'],                   
              command: () => {
                this.activeItem = '/analytics/delivered/2023';
                this.router.navigate(['/analytics/delivered/2023']);
              },
              disabled: ( this.userRole() === 'guest' ) ? true : false   //! ROLE PERMISSION
            } 
        ]
      },
      {  //** SETTINGS ************************
        key: '6',
        label: this.translationServ.translationsSIG()?.['label-settings'] ?? 'label-settings' ,  
        icon: 'pi pi-cog',  
        expanded: this.expandIfIn(['/translations', '/gpt']),
        items: [
          // {
          //     key: '5_0',
          //     label: this.translationServ.translationsSIG()?.['label-languages'] ?? 'label-languages' , 
          //     icon: 'pi pi-language',
          //     routerLinkActive: 'menuactive', 
          //     routerLink: ['/languages'],   
          //     command: () => {
          //       this.activeItem = '/languages';
          //       this.router.navigate(['/languages']);
          //     }
          // },
          {
            key: '6_0',
            label: this.translationServ.translationsSIG()?.['label-translations'] ?? 'label-translations' , 
            icon: 'pi pi-language',
            routerLinkActive: 'menuactive', 
            routerLink: ['/translations'],  
            command: () => {
              this.activeItem = '/translations';
              this.router.navigate(['/translations']);
            },
            disabled: ( this.userRole() === 'guest' ) ? true : false  //! ROLE PERMISSION
          },
          {
            key: '5_1',
            label: this.translationServ.translationsSIG()?.['label-chatgpt'] ?? 'label-chatgpt' , 
            icon: 'pi pi-microchip-ai',
            routerLink: ['/gpt'],  
            routerLinkActive: 'menuactive', 
            command: () => {
              this.activeItem = '/gpt';
              this.router.navigate(['/gpt']);
            },
            visible: ( this.userRole() === 'superadmin' ) ? true : false  //! ROLE PERMISSION
          } 
        ] 
      }, 
      // {
      //   key: '-',
      //   label: 'xxxxxx',
      //   icon: 'pi pi-info-circle',
      //   style: {'height': '20px'},
      //   disabled: false,
      //   visible: true,
      //   items: [],
      //   badge: 'info' ,
      //   badgeStyleClass: 'pi p-badge-info',
      //   badgeValue: '2',
      //   badgePosition: 'top right',
      //   badgeSize: 'normal',
      //   badgeTemplate: 'badge',
      //   badgeSeverity: 'info',
      //   badgeIcon: 'pi pi-info-circle',
      //   badgeClass: 'badge',
      //   badgeStyle: 'background-color: red; color: yellow;',
      //   badgeContainerClass: 'badge-container',
      //   badgeContainerStyle: 'background-color: #007ad9; color: #ffffff;',
      //   badgeValueTemplate: 'badgeValue',
      //   badgeValueClass: 'badge-value',
      //   badgeValueStyle: 'color: #ffffff;',
      //   badgeValueContainerClass: 'badge-value-container',
      //   badgeValueContainerStyle: 'color: #ffffff;',
      //   badgeValuePosition: 'top right',
      //   badgeValueSize: 'normal',
      //   badgeValueSeverity: 'info',
      //   badgeValueIcon: 'pi pi-info-circle',  
      // },
      {  //** LOGOUT ************************
        key: '7',
        label: 'LOGOUT', 
        icon: 'pi pi-sign-out',
        style: {'position': 'fixed', 'bottom': '147px', 'width': '16.5rem'},
        command: () => this.logout()
      } 
    ];
  }

  // expand menu if active route is included in the routes array
  private expandIfIn(routes: string[]): boolean {   
    let includedRoute = false; 
    routes.find((route) => { 
      if(this.activeItem === route ) {
        includedRoute =  true;
        return; 
      } 
    });   
    return includedRoute ;
  }

  selectLang(lang: any) {   
    this.translationServ.setCookieFavouriteLNG(lang.value, 'from sidebar');
    this.translationServ.currentLangSIG.set(lang.value); 
    this.selectedLang = {...lang};   
    this.translationServ.retrieveAllTerms();  
  }

   
  logout() {
    this.toggeleSidenav.emit('');  
    this.fbAuthService.currentUserSignal.set(null); 
    this.fbAuthService.crmCurrentUser.set(null);
    this.router.navigate(['/login']); 
  }
 
 

}

<p-autoComplete 
    #semanticNavigatorComponent
    autofocus="false"
    [(ngModel)]="selectedItem" 
    [group]="true" 
    [suggestions]="filteredGroups()" 
    (completeMethod)="filterGroupedItems($event, semanticNavigatorComponent)" 
    (onFocus)="onFocusTriggered($event, semanticNavigatorComponent)"
    (onSelect)="onItemSelect($event, semanticNavigatorComponent)" 
    placeholder=" Naviga per contenuti...">  
      <ng-template let-item pTemplate="item">    
            <div  class=" inbox" >       
              <div class=" item">
                  <p-avatar 
                      icon="pi {{ item.icon }}" 
                      styleClass="icon" 
                      [style]="{ 'background-color': item.bgcolor , color: item.color }" />

                  <div class=" text " [title]="item.name ">
                      <span class="name">{{ item.name }}</span>
                      <span class="description">{{ item.description }}</span> 

                      <p-tag [style]="{ float: 'right' }"
                        [value]="item.chipText" [rounded]="true"  icon="pi pi-angle-right"  /> 
                  </div> 
              </div> 
            </div> 
      </ng-template>
</p-autoComplete> 
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PrimeNGautocompleteObject } from '../../../../interfaces/multi-search-res';



@Injectable({
  providedIn: 'root'
})
export class MultiSearchService {

  constructor( private http : HttpClient) { } 

  //* MULTISEARCH
  multiSearchByQuery(query: string ): Observable< any > {   
 
    const url = `${env.baseServerURL}general/spotlight?q=${query}`;   
    
    return this.http.get<any>(url, { 
      observe: 'response'
    }).pipe(
      map(response => {   
          // questa interfaccia è mandatory 
          const res : PrimeNGautocompleteObject[] = [...response.body]; 
          return res ; 
      })
    );
  } 
}

import { NgFor } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';  
import { MultiSearchService } from './services/multi-search.service';
import { PrimeNGautocompleteObject } from '../../../interfaces/multi-search-res';
import { TagModule } from 'primeng/tag';

// interface AutoCompleteCompleteEvent {
//   originalEvent: Event;
//   query: string;
// }

@Component({
  selector: 'app-multi-search',
  standalone: true,
  imports: [  
    AvatarModule, 
    BadgeModule, 
    AutoCompleteModule,  
    ChipModule, 
    TagModule,        
    FormsModule,       
    NgFor           
  ],
  templateUrl: './multi-search.component.html',
  styleUrl: './multi-search.component.scss'
})
export class MultiSearchComponent { 
 
   selectedItem: any = null;
   filteredGroups = signal<any[]>([]);
   groupItems = signal<any[]>([]); 
 
  constructor(
    private multiSearchServ: MultiSearchService,
    // private filterService: FilterService, // Multi Search : CLIENT FILTER °°°°°°°°°°
    private router : Router) { }

 
  filterGroupedItems(event: AutoCompleteCompleteEvent, component: any) { 
    let query = event.query; 
    // let filteredGroups = []; 

    this.multiSearchServ.multiSearchByQuery(query).subscribe({
      next: (res: PrimeNGautocompleteObject[]) => {  
        this.groupItems.set([...res]); 

        // CLIENT FILTER ----------
        // for (let group of this.groupItems()) {
        //     let filteredSubOptions = this.filterService.filter(group.data, ['name', 'description'], query, "contains");  
        //     if (filteredSubOptions && filteredSubOptions.length) {
        //         filteredGroups.push({
        //             label: group.label, 
        //             items: [...group.data] 
        //         }); 
        //     }
        // }  
        //  this.filteredGroups.set( [...filteredGroups]); 
        //---------------------------
          
        this.filteredGroups.set( [...res]);  
      }
    }); 
  }  

  onFocusTriggered(event: any, component: any) { 
    this.filterGroupedItems({originalEvent: event, query: ''}, component );  
    component.show();  // Force to show the dropdown 
  }

  onItemSelect(event: any, component: any) {   
    const url = event.value.link;
    if (url) {
      if(url.includes('http')) {
        window.open(url, '_blank'); 
      }else{
        this.router.navigate([url]);
      }   

      this.filteredGroups.set([]);
      this.selectedItem = null; 
      component.hide();  // Force to hide the dropdown 
      component.clear(); // Clear the input field  
    
      setTimeout(() => {   // altrimenti non perde il focus
          if (component.inputEL && component.inputEL.nativeElement) { 
              component.inputEL.nativeElement.blur(); // Remove focus from the input field
          }
      }, 100);
    }

  }  

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';    

@Component({
  selector: 'app-app-version',
  standalone: true,
  imports: [
    DatePipe,
    UpperCasePipe
  ],
  template: `<div>
                <i>Version:</i>&nbsp;&nbsp;<b>{{ appVersion }}</b> 
                <br>
                <i>Environment:</i>&nbsp;&nbsp;<b>{{ environment | uppercase }}</b>
                <br>
                <i>Updated:</i>&nbsp;&nbsp;<b>{{ lastUpdate | date:'dd-MM-yyyy HH:mm:ss' }}</b>
             </div>
            ` ,
  styles:  [` 
      div{
        color: var(--surface-400);
      }
  `]
})
export class AppVersionComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();  
  appVersion = '';
  lastUpdate = '';
  environment: any = env.environment ;
  tag = '';

  constructor(private http: HttpClient) {  }

  ngOnInit(): void {
    setTimeout(() => {
       this.getVersionInfo();
    }, 2000); 
  }

  getVersionInfo()  { 

        const url = '/assets/json/version-info.json';   

        this.http.get<any>(url).pipe(
          takeUntil(this.unsubscribe$),
        ).subscribe({ 
          next: (data) => {     
            this.appVersion = data.version;
            this.lastUpdate = data.buildDate; 
            this.environment = data.environment;
            this.tag = data.tag;
          } 
        });
  } 

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
 
}

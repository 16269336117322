<mat-toolbar  class="headerToolbar"  >
  @if(loggedUser()){
    <button mat-icon-button (click)="toggleSidenav()" >
      <mat-icon>menu</mat-icon>
    </button> 
  }
  <img src="assets/img/logoOn.png" alt="On Logo" [routerLink]="'/'" style="cursor:pointer">         
  <span [routerLink]="'/'" class="siteTitel" > {{ title }} </span>  

  <!-- DEBUG CHIP  -->
  <p-chip styleClass="containerChip">
      <span class="chipDebug bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">
        {{subtitle}}
      </span>
      <span class="ml-2 font-medium">
        {{ environment.environment }}
      </span>
  </p-chip>

  <span class="spacer"></span>  


 

  @if(theme === 'dark-theme'){
    <button mat-icon-button (click)="toggleTheme()" > <mat-icon>light_mode</mat-icon> </button>
  } @else {
    <button mat-icon-button (click)="toggleTheme()" > <mat-icon>dark_mode</mat-icon> </button>
  }  

  <!-- ECCO COME GESTIRE MULTI THEME  -->
  <!-- <p-button (click)="customTheme()"  label="ON theme"  styleClass="p-button-text"></p-button> -->
   
  

  @if(loggedUser()){
      <div style="display: flex; align-items: center; column-gap: .3rem; font-size: 14px;">   
              <p-avatar 
                  icon="pi pi-user" 
                  styleClass="userAvatar"                           
                  [style]="{ 'background-color': '#ece9fc', color: '#2a1261' }" 
                  shape="circle" /> 
          <span>  {{ loggedUser()?.displayName  }} </span> 
          <span><small> ( {{ loggedUser()?.role  }} )</small></span>
      </div>
  } @else {
      <p-avatar  
          icon="pi pi-user"  
          styleClass="userAvatar"                       
          [style]="{ 'margin': ' 10px 0', color: 'lightgray'}" 
          shape="circle" />   
  }  


 
  <!-- NAVIGATORE SEMANTICO  -->
  <app-multi-search />  

 
</mat-toolbar>  

 
 
<!-- INCOMING CHAT  -->
<p-toast    
    position="bottom-right"    
    (onClose)="onReject($event.message)" 
    [showTransformOptions]="'translateY(100%)'" 
    [showTransitionOptions]="'1000ms'" 
    [hideTransitionOptions]="'1000ms'" 
    [showTransformOptions]="'translateX(100%)'"
    [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="min-width: 50rem; flex flex-column items-start" style="flex: 1">
                <div style="margin-bottom: 1rem" class="flex items-center gap-2 ">
                    <p-avatar 
                        icon="pi pi-user" 
                        shape="circle" />
                    <span style="font-weight: 600;" >
                      {{ message.data.senderName }}
                    </span>
                </div>
                
                <div style="font-size:large">
                    {{ message.data.text | emoji }}
                </div>
                
                <div style="width: 100%; margin-top: 1rem;" class="flex items-center gap-2 justify-between"> 
                  <span style=" font-size: small; color: #939090"  >
                    {{ message.data.timestamp | date:'dd-MM-yyyy HH:mm:ss' }}
                  </span>
                  <p-button icon="pi pi-reply" size="small" label="Rispondi" (click)="response( message )"   />
                </div> 
            </div>
        </ng-template> 
</p-toast> 
<mat-sidenav-container class="content-container {{ currentScreenSize }}"  >   
  <mat-sidenav #sidenav  mode="over" [opened]="isSidebarOpen"  > 
    <app-sidebarmenu  (toggeleSidenav)="sidenav.toggle()"   ></app-sidebarmenu> 
  </mat-sidenav>
  <!-- <mat-sidenav opened mode="side" position="end">End content</mat-sidenav> -->
  <mat-sidenav-content>  
      <app-header [title]="title" [subtitle]="currentScreenSize"  (toggeleSidenav)="sidenav.toggle()"  ></app-header>  
      <div class="content-wrapper" > 
        <router-outlet></router-outlet>
      </div> 
  </mat-sidenav-content>
</mat-sidenav-container> 


<!-- TOAST GLOBALI  -->
<p-toast position="bottom-right"></p-toast> 

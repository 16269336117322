import { Component, OnDestroy, OnInit, inject  } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';   
import { MatSidenavModule } from '@angular/material/sidenav'; 
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout'; 
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { HeaderComponent } from './features/components/header/header.component';   
import { SidebarmenuComponent } from './features/components/sidebarmenu/sidebarmenu.component'; 
import { ToastModule } from 'primeng/toast';
import { TranslationService } from './features/views/translations/services/translations.service';
import { FirebaseAuthService } from './features/views/auth/services/firebase-auth.service';
 
@Component({
  selector: 'app-root',
  standalone: true, 
  imports: [
    RouterOutlet, 
    RouterModule, 
    HeaderComponent, 
    SidebarmenuComponent,
    MatSidenavModule,
    ToastModule 
  ],    
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent  implements OnInit, OnDestroy { 
  title = 'On eMSP ';  
  isSidebarOpen = false;

  private unsubscribe$: Subject<void> = new Subject<void>();  

  fbAuthService = inject(FirebaseAuthService);

  isHandset: boolean = true; 
  // isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)'); 
  currentScreenSize: string = ' kkkkkkk '; //! break points for entire APP

  // Create a map to display breakpoints names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xs'],
    [Breakpoints.Small, 'sm'],
    [Breakpoints.Medium, 'md'],
    [Breakpoints.Large, 'lg'],
    [Breakpoints.XLarge, 'xl'],
  ]); 

  constructor(
    private tranService: TranslationService,
    private breakpointObserver: BreakpointObserver
  ) {

    // initialize the app TRANSLATIONS **********************   
     this.tranService.retrieveAllTerms(); 
    // ******************************************************

    // Subscribe to the breakpoints Observer to set the app brakepoints.
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? ' no-size';
          }
        }
      }); 
    
  }


  ngOnInit(): void { 
    this.fbAuthService.isUserLoggedIn();  
  } 
  

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}




 